import React from "react";
import { IonRippleEffect,IonImg } from "@ionic/react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./RippleComponent.scss";
import Typography from "../../components/typo/Typography";
import { connect } from "react-redux";

interface customProps{
  clickHandler?: Function;
  style?: any;
  src?:any;
  typoText?:string;
  clsName?:string;
}

const mapStateToProps = (state: any) => ({
  displayIndexPosition: state.display.items.length,
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  customProps;
const RippleComponent: React.FC<Props> = ({
  clickHandler,
  style,
  src,
  typoText,
  clsName,
  displayIndexPosition,
  ...props
}) => {
  function handleButtonClick() {
    clickHandler?.();
  }

let positionClass:any 
if (displayIndexPosition===1){
  positionClass = "icon-image"
}
if (displayIndexPosition>1){
  positionClass = "icon-image-1"
}
  
  console.log(positionClass,"123");
  return (
    <div className="ripple-wrapper">
    <span className="ion-activatable ripple-parent">
    <IonRippleEffect type="bounded" className="ripple-effect1"></IonRippleEffect>
    <IonImg src={src} className={positionClass} onClick={() => handleButtonClick()} />
    <Typography variant="body-bold" color="dark" className={clsName} >{typoText}</Typography>
    </span>
    </div>
  );
};
export default withTranslation()(connect(mapStateToProps)(RippleComponent)
);
