import React, { useState, useEffect } from "react";
import {
  IonCard,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonRefresher,
  IonRefresherContent,
  IonCol,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import HeaderComponent from "../components/header/Header";
import "../theme/variables.scss";
import Typography from "../components/typo/Typography";
import { withTranslation, WithTranslation } from "react-i18next";
import { version } from "../../package.json";
import "./SettingTab.scss";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import { Auth } from "aws-amplify";

const mapStateToProps = (state: any) => ({
  customer: state.user.customer,
});
type Props = WithTranslation;

const SettingTab: React.FC<Props> = (props) => {
  const { t } = props;
  const history = useHistory();
  const [text, setText] = useState<string>();
  const [userAccountEmail, setUserAccountEmail] = useState("");
  useEffect(() => {
    if (userAccountEmail === "") {
      getAccountInfo();
    }
  }, [userAccountEmail]);

  // get user email
  const getAccountInfo = async () => {
    await Auth.currentAuthenticatedUser().then((data) => {
      setUserAccountEmail(data["attributes"]["email"]);
    });
  };
  const refresh = (e: CustomEvent) => {
    getAccountInfo();
    e.detail.complete();
  };

  function goBack() {
    history.replace("/");
  }
  async function Logout() {
    await Auth.signOut().then(() => history.replace("/"));
  }
  return (
    <IonPage id="MoreView">
      <HeaderComponent
        headerLable={t("pelzer-settings-header")}
        showBackButton={false}
        showLogo={false}
      />
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonGrid className="setting-container">
          <IonRow>
            <IonCol
              size-sm="12"
              size-md="6"
              offset-md="3"
              size-lg="6"
              offset-lg="3"
              size-xl="4"
              offset-xl="4"
              className="moreContainer"
            >
              <IonCard className="moreCard">
                <div className="icon">
                  <SentimentSatisfiedIcon
                    fontSize="small"
                    className="moreCardIonThumbnailIcon"
                  />
                </div>
                <div className="labels">
                  <Typography variant="body1" color="dark">
                    {userAccountEmail}
                  </Typography>
                </div>
              </IonCard>
              <div className="pelzer-heading-wrapper">
                <Typography
                  variant="heading"
                  color="dark"
                  className="pelzer-settings-title"
                >
                  {t("pelzer-settings-title")}
                </Typography>

                <Typography
                  variant="caption"
                  color="subdued"
                  className="pelzer-settings-subtitle"
                >
                  {t("pelzer-settings-subtitle")}
                </Typography>

                <Typography
                  variant="body1"
                  color="brand"
                  className="pelzer-settings-number"
                >
                  {t("pelzer-settings-number")}
                </Typography>
              </div>

              <button className="pelzer-settings-below-button" onClick={Logout}>
                <Typography
                  variant="button-font"
                  color="dark"
                  className="pelzer-settings-below-button-inside"
                >
                   {t("pelzer-settings-logout")}
                </Typography>
              </button>

              <div className="ion-text-center">
                <Typography
                  variant="caption"
                  color="subdued"
                  className="pelzer-settings-version"
                >
                  {t("pelzer-settings-version")} {version}
                </Typography>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(SettingTab);
