import { Auth } from "aws-amplify";

export const configDev = {
  aws_project_region: "eu-central-1",
  Auth: {
    identityPoolId: "eu-central-1:6da6d2fa-dd8d-4150-8af4-3b948aa5f5e3",
    region: "eu-central-1",
    userPoolId: "eu-central-1_mEHsW7PV0",
    userPoolWebClientId: "3ukv0itj4ks4otloqjsq4ust10",
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "pelzer-cloud-api",
        endpoint:
          "https://zj5yyk3js9.execute-api.eu-central-1.amazonaws.com/dev",
        region: "eu-central-1",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getAccessToken()
              .getJwtToken(),
          };
        },
      },
    ],
  },
};

export const configProd = {
  aws_project_region: "eu-central-1",
  Auth: {
    identityPoolId: "eu-central-1:6da6d2fa-dd8d-4150-8af4-3b948aa5f5e3",
    region: "eu-central-1",
    userPoolId: "eu-central-1_mEHsW7PV0",
    userPoolWebClientId: "3ukv0itj4ks4otloqjsq4ust10",
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "dev-pelzer-cloud-api",
        endpoint:
          "https://owhput7g2m.execute-api.eu-central-1.amazonaws.com/dev",
        region: "eu-central-1",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getAccessToken()
              .getJwtToken(),
          };
        },
      },
    ],
  },
};
