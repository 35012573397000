import React, { useState } from "react";
import { connect } from "react-redux";
import {
  IonPage,
  IonGrid,
  IonRow,
  IonImg,
  IonApp,
  IonCol,
  IonIcon,
  IonModal,
} from "@ionic/react";
import Typography from "../components/typo/Typography";
import "./PelzerStore.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { chevronForwardOutline } from "ionicons/icons";
import { getMedia, getMediaSearch } from "../data/media/MediaAction";
import MediaList from "../components/mediaList/MediaList";
import { MediaItem } from "../../src/data/media/Media";
import { postActionAndPreviewDisplay } from "../data/action/applyAction";

const mapStateToProps = (state: any) => ({
  mediaList: state.MediaList,
  media: state.media.StoreItems,
  activeDisplay: state.display.activeDisplay,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMediaDispatch: () => dispatch(getMedia()),

  getMediaSearchDispatch: () =>
    dispatch(getMediaSearch()),

  postActionAndPreviewDisplayDispatch: (id: string, selDisplay: any) =>
    dispatch(postActionAndPreviewDisplay(id, selDisplay)),
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const PelzerStore: React.FC<Props> = ({
  getMediaSearchDispatch,
  t,
  postActionAndPreviewDisplayDispatch,
  activeDisplay,
}) => {
  const [showStoreList, setShowStoreList] = useState(false);

  const openStoreList = () => {
    console.log("getMedia calling and setting showmedia list to true");
    getMediaSearchDispatch();
    setShowStoreList(true);
  };

  const onCloseStoreList = () => {
    console.log("Closing store list");
    setShowStoreList(false);
  };

  const onSelectPreview = (media: MediaItem) => {
    console.log(
      "After select image post action and get display in %s",
      activeDisplay
    );
    console.log(
      "After select image post action and get display for media %s",
      media.name
    );
    //TODO currentdisplay is set default
    postActionAndPreviewDisplayDispatch(media.id, activeDisplay);
  };

  // TODO Needs to reworked to not send to media list
  const onSelectPurchase = () => {};

  return (
    <>
      <IonPage>
        <IonApp>
          <IonGrid className="store-container">
            <IonRow className="pelzer-store-image-section">
              <IonImg
                src="https://d1ww7obalfwgm7.cloudfront.net/images_32_9/Flowers.jpeg"
                alt="image_not_found"
              />
            </IonRow>
            <IonRow style={{ display: "flex"}}>
              <div className="pelzer-heading-wrapper">
                <Typography
                  variant="heading"
                  color="dark"
                  className="pelzer-store-title"
                >
                  {t("pelzer-store-title")}
                </Typography>

                <Typography
                  variant="caption"
                  color="subdued"
                  className="pelzer-store-subtitle"
                >
                  {t("pelzer-store-subtitle")}
                </Typography>

                <IonRow className="pelzer-store-button" onClick={openStoreList}>
                  <IonCol size="5">
                    {" "}
                    <Typography
                      variant="body"
                      color="dark"
                      className="pelzer-store-button-text"
                    >
                      {t("mediastore-purchase")}
                    </Typography>
                  </IonCol>
                  <IonCol size="2">
                    <IonIcon
                      className="forward-icon-for-store"
                      icon={chevronForwardOutline}
                      onClick={openStoreList}
                    />
                  </IonCol>
                </IonRow>
              </div>
            </IonRow>
              <IonModal cssClass="mediaModal" isOpen={showStoreList}>
                <MediaList
                  mode={"store"}
                  onClose={onCloseStoreList}
                  onSelection={onSelectPreview}
                  onSelectDownload={onSelectPurchase}
                />
              </IonModal>
          </IonGrid>
        </IonApp>
      </IonPage>
    </>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PelzerStore)
);
