import { combineReducers } from "redux";

import { notifications } from "./components/notifications/Notifications";
import { actions } from "./data/action/action";
import { media } from "./data/media/Media";
import { display } from "./data/display/Display";
// import {youtube} from "./data/action/action";

const appReducers = combineReducers({
  notifications,
  actions,
  media,
  display,

});

export default appReducers;
