import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  IonContent,
  IonPage,
  IonRow,
  IonGrid,
  IonSpinner,
  IonCol,
} from "@ionic/react";
import { withTranslation, WithTranslation } from "react-i18next";
import HeaderComponent from "../components/header/Header";
import { IonSlides, IonSlide } from "@ionic/react";
import DisplayCard from "../components/displayCard/DisplayCard";
import Typography from "../components/typo/Typography";
import { getDisplay } from "../data/display/DisplayAction";
import { useIonViewWillEnter } from "@ionic/react";
import "./Home.scss";
import { changeActiveDisplay } from "../../src/data/display/DisplayAction";
import { playConfigType } from "../../src/data/display/DisplayAction";
import { DisplayItem } from "../data/display/Display";

const mapStateToProps = (state: any) => ({
  displays: state.display.items,
  mediaLoading: state.actions.loading,
  displayLoading: state.display.loading,
  display: state.display.id,
  playConfigYoutube: state.display,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDisplayDispatch: () => dispatch(getDisplay()),
  changeActiveDisplayDispatch: (selDisplay: any) =>
    dispatch(changeActiveDisplay(selDisplay)),
  playConfigMediaDispatch: (PlayConfigMedia: any) =>
    dispatch(playConfigType(PlayConfigMedia)),
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Home: React.FC<Props> = (props) => {
  const {
    getDisplayDispatch,
    changeActiveDisplayDispatch,
    playConfigMediaDispatch,
    displays,
    displayLoading,
    t,
  } = props;
  const [refreshTime, setRefreshTime] = useState(new Date().getTime());
  const [fetchDisplayData, setFetchDisplayData] = useState(true);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  useIonViewWillEnter(() => {
    setRefreshTime(new Date().getTime());
  });

  useEffect(() => {
    getDisplayDispatch();
  },[]);

  useEffect(() => {
    getDisplayDispatch();
  }, [refreshTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      //Checks if page is in focus and checking variable is true to fetch display details
      if (document.hasFocus() && fetchDisplayData) {
        getDisplayDispatch();
        console.log("display fetch active:", fetchDisplayData);
      }
      else {
        console.log("display fetch inactive:", fetchDisplayData);
      }
      //Adjust interval to call get api
    }, 5000);
    return () => clearInterval(interval);
  }, [fetchDisplayData]);


  const slideDidChange = (index: any) => {
    changeActiveDisplayDispatch(
      displays[index.target.dom7ElementDataStorage.swiper.activeIndex].id
    );
    playConfigMediaDispatch(
      index.target.dom7ElementDataStorage.swiper.activeIndex
    );
  };

  const setFetchState = async (value: boolean) => {
    setFetchDisplayData(value);
  }

  const renderOneDisplay = (display: any) => {
    return <DisplayCard display={display} fetchDisplay={setFetchState} />;
  };

  const renderMultipleDisplays = (displays: DisplayItem[]) => {
    return (
      <IonSlides
        pager={true}
        options={slideOpts}
        className="my-custom-slider"
        onIonSlideDidChange={slideDidChange}
        onIonSlideTap={slideDidChange}
        onIonSlidesDidLoad={slideDidChange}
      >
        {displays.map((display: any, index: any) => {
          return (
            <IonSlide key={index} className="ion-slide-wrapper">
              {renderOneDisplay(display)}
            </IonSlide>
          );
        })}
      </IonSlides>
    );
  };

  return (
    <>
      <IonPage>
        <HeaderComponent
          headerLable="common.header"
          showBackButton={false}
          showLogo={true}
        />

        <IonContent className="home-container" fullscreen>
          <IonGrid>
            <IonRow style={{ display: "flex", flexWrap: "nowrap" }}>
              <div className="home-heading">
                <Typography
                  variant="heading"
                  color="dark"
                  className="heading-title"
                >
                  {t("home-title")}
                </Typography>
                <Typography
                  variant="body"
                  color="subdued"
                  className="heading-subtitle"
                >
                  {t("home-subtitle")}
                </Typography>
              </div>
            </IonRow>

            <IonRow>
              {displays?.length > 1 ? (
                renderMultipleDisplays(displays)
              ) : displays?.length == 1 ? (
                renderOneDisplay(displays[0])
              ) : displayLoading ? (
                <IonSpinner name="crescent" className="loading-Spinner" />
              ) : (
                <IonCol size="12">
                  <Typography variant="body" color="dark" className="nodisplay">
                    {t("home-nodisplay")}
                  </Typography>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);
