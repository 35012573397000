import { Redirect, Route, Switch } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { imageOutline, albumsOutline, settingsOutline } from "ionicons/icons";
import Home from "./pages/Home";
import PelzerStore from "./pages/PelzerStore";
import SettingTab from "./pages/Settingtab";
import { withAuthenticator } from "aws-amplify-react";

import SignInMagnWall from "./pages/auth/SignInMagnWall";
import RequireNewPasswordForPelzer from "./pages/auth/RequireNewPasswordForPelzer";
import ForgotPasswordForPelzer from "./pages/auth/ForgotPasswordForPelzer";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
// import "./theme/variables.css";
import "./theme/variables.scss";
import "./app.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import ServiceWorkerWrapper from "./components/notifications/ServiceWorkerWrapper";

type Props = WithTranslation;

const App: React.FC<Props> = (props) => {
  const { t } = props;
  return (
    <IonApp >
      <ServiceWorkerWrapper />
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
          <Switch>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/store">
              <PelzerStore />
            </Route>
            <Route exact path="/settings">
              <SettingTab />
            </Route>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </Switch>
          </IonRouterOutlet>
          <IonTabBar slot="bottom" className="tab-wrapper">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={imageOutline} />
              <IonLabel>{t("tabs-home")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="store" href="/store">
              <IonIcon icon={albumsOutline} />
              <IonLabel>{t("tabs-store")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="settings" href="/settings">
              <IonIcon icon={settingsOutline} />
              <IonLabel>{t("tabs-settings")}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default withTranslation()(
  withAuthenticator(App, false, [
    <SignInMagnWall />,
    <RequireNewPasswordForPelzer />,
    <ForgotPasswordForPelzer />,
  ])
);
