import {
  POST_ACTION_REQUEST,
  POST_ACTION_FAILURE,
  POST_ACTION_SUCCESS,
  DISPLAY_START_LOADING,
  DISPLAY_FINISH_LOADING,
  YOUTUBE_LOADING_START,
  YOUTUBE_LOADING_COMPLETE,
} from "./applyAction";

export interface YoutubeType {
  yt_id: any;
  yt_type: string;
  yt_url: string;
}

export interface YoutubeItem {
  yt_id: any;
  yt_type: string;
  yt_url: string;
  YoutubeType: YoutubeType;
}

export interface Action {
  display_ID: string;
  config_type: string;
  media: string;
}

interface State {
  loading: boolean;
  displayloading: boolean;
  items: YoutubeItem[];
}

const initialStateAction: State = {
  loading: false,
  displayloading: false,
  items: [],
};

export const actions = (state: State = initialStateAction, action: any) => {
  switch (action.type) {
    case POST_ACTION_REQUEST:
      return Object.assign({}, state, { loading: true });
    case POST_ACTION_FAILURE:
      return Object.assign({}, state, { loading: false });
    case POST_ACTION_SUCCESS:
      return Object.assign({}, state, { loading: false });
    case YOUTUBE_LOADING_START:
      return Object.assign({}, state, {
        loading: true,
        //items: action.response.data,
        items: action.response,
      });
    case YOUTUBE_LOADING_COMPLETE:
      return Object.assign({}, state, { loading: false });
    case DISPLAY_START_LOADING:
      return Object.assign({}, state, { displayloading: true });
    case DISPLAY_FINISH_LOADING:
      return Object.assign({}, state, { displayloading: false });
    default:
      return state;
  }
};
