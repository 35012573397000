import React from "react";
import {
  GET_DISPLAY_REQUEST,
  GET_DISPLAY_FAILURE,
  GET_DISPLAY_SUCCESS,
  DISPLAY_CHANGE,
  PLAY_CONFIG,
} from "./DisplayAction";

export interface DisplayType {
  id: number;
  aspectRatio: string;
  height: string;
  width: string;
  model: string;
}
export interface MediaType {
  id: number;
  name: string;
  contentType: string;
  aspectRatio: string;
  width: string;
  height: string;
}

export interface MediaConfig {
  id: string;
  name: string;
  thumbnailUrl: string;
  url: string;
  mediaType: MediaType;
}

export interface MediaItem {
  media_config: MediaConfig;
  state: string;
}
export interface DisplayItem {
  id: string;
  displayName: string;
  customerId: number;
  easyvisPlayer: string;
  displayType: DisplayType;
  status: MediaItem;
  playConfig: any;
}

interface State {
  loading: boolean;
  activeDisplay: number|undefined;
  playConfigYoutubeIcon:any;
  items: DisplayItem[];
}

const initialStateDisplay: State = {
  loading: false,
  activeDisplay: undefined,
  playConfigYoutubeIcon:undefined,
  items: [],
};

export const display = (state: State = initialStateDisplay, action: any) => {
  switch (action.type) {
    case GET_DISPLAY_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_DISPLAY_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_DISPLAY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        items: action.response.data,
      });
    case DISPLAY_CHANGE:
      return Object.assign({}, state, { activeDisplay: action.selDisplay });
    case PLAY_CONFIG: 
      return Object.assign({}, state, {playConfigYoutubeIcon: action.PlayConfigMedia});

    default:
      return state;
  }
};
