import { apiCall } from "../../common/api";
import {
  getDisplayApi,
  GET_DISPLAY_FAILURE,
  GET_DISPLAY_REQUEST,
  GET_DISPLAY_SUCCESS,
} from "../display/DisplayAction";

export const POST_ACTION_REQUEST = "POST_ACTION_REQUEST";
export const POST_ACTION_FAILURE = "POST_ACTION_FAILURE";
export const POST_ACTION_SUCCESS = "POST_ACTION_SUCCESS";

export const DISPLAY_START_LOADING = "DISPLAY_START_LOADING";

export function loadingDisplayStart() {
  return { type: DISPLAY_START_LOADING };
}
export const DISPLAY_FINISH_LOADING = "DISPLAY_FINISH_LOADING";

export function loadingDisplayFinish() {
  return { type: DISPLAY_FINISH_LOADING };
}

export const postAction = (id: string, selDisplay: any) => {
  let apiName = "pelzer-cloud-api";
  let path = "/action";
  let myInit = {
    headers: {},
    body: {
      display_id: selDisplay,
      action_type: "PLAY",
      action: {
        media_id: id,
      },
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const postActionAndReloadDisplay =
  (id: string, selDisplay: any) => (dispatch: any, getState: any) => {
    dispatch(loadingDisplayStart());
    dispatch({ type: POST_ACTION_REQUEST });

    return (
      postAction(id, selDisplay).then((response) => {
        console.log("Posted action successful, fetching display information");
        dispatch({ response, type: POST_ACTION_SUCCESS });
        dispatch({ response, type: GET_DISPLAY_REQUEST });
        getDisplayApi().then(
          (response) => {
            dispatch(loadingDisplayFinish());
            dispatch({ response, type: GET_DISPLAY_SUCCESS });
          },
          (error: any) => {
            console.log("Error happened during get display");
            dispatch(loadingDisplayFinish());
            dispatch({ error, type: GET_DISPLAY_FAILURE });
          }
        );
      }),
      (error: any) => {
        console.log("Error happened during posting action");
        dispatch(loadingDisplayFinish());
        dispatch({ error, type: POST_ACTION_FAILURE });
      }
    );
  };

export const postActionPreview = (id: string, selDisplay: any) => {
  let apiName = "pelzer-cloud-api";
  let path = "/action";
  let myInit = {
    headers: {},
    body: {
      display_id: selDisplay,
      action_type: "PREVIEW",
      action: {
        media_id: id,
      },
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};
  

export const postActionAndPreviewDisplay =
(id: string, selDisplay: any) => (dispatch: any, getState: any) => {
  dispatch({ type: POST_ACTION_REQUEST });
  return (
    postActionPreview(id, selDisplay).then((response) => {
      console.log("Posted action successful, fetching display information");
      dispatch({ response, type: POST_ACTION_SUCCESS });
    }),
    (error: any) => {
      console.log("Error happened during posting action");
      dispatch({ error, type: POST_ACTION_FAILURE });
    }
  );
};

export const playConfigDetail = (PlayConfigMedia: any) => {
  let apiName = "pelzer-cloud-api";
  let path = "/action";
  let myInit = {
    headers: {},
    body: {
      display_id: PlayConfigMedia,
      action_type: "CONFIG",
      action: {
      },
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const playConfigYoutubeMedia =
(PlayConfigMedia: any) => (dispatch: any, getState: any) => {
  dispatch({ type: POST_ACTION_REQUEST });
  return (
    playConfigDetail(PlayConfigMedia).then((response) => {
      console.log("Posted action successful, fetching display information");
      dispatch({ response, type: POST_ACTION_SUCCESS });
    }),
    (error: any) => {
      console.log("Error happened during posting action");
      dispatch({ error, type: POST_ACTION_FAILURE });
    }
  );
};


export const postActionON = (selDisplay: any) => {
  let apiName = "pelzer-cloud-api";
  let path = "/action";
  let myInit = {
    headers: {},
    body: {
      display_id: selDisplay,
      action_type: "ON",
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

//post action for display ON from toggle with get display action
export const postActionONAndGetDisplay =
  (selDisplay: any) => (dispatch: any, getState: any) => {
    dispatch(loadingDisplayStart());
    dispatch({ type: POST_ACTION_REQUEST });

    return (
      postActionON(selDisplay).then((response) => {
        console.log("Posted action successful, setting display to default");
        dispatch({ response, type: POST_ACTION_SUCCESS });
        dispatch({ response, type: GET_DISPLAY_REQUEST });
        (getDisplayApi()).then(
          (response) => {
            console.log("get display success");
            dispatch(loadingDisplayFinish());
            dispatch({ response, type: GET_DISPLAY_SUCCESS });
          },
          (error: any) => {
            console.log("Error happened during get display");
            dispatch({ error, type: GET_DISPLAY_FAILURE });
            dispatch(loadingDisplayFinish());
          }
        );
      }),
      (error: any) => {
        console.log("Error happened during posting action");
        dispatch({ error, type: POST_ACTION_FAILURE });
        dispatch(loadingDisplayFinish());
      }
    );
  };

export const postActionOFF = (selDisplay: any) => {
  let apiName = "pelzer-cloud-api";
  let path = "/action";
  let myInit = {
    headers: {},
    body: {
      display_id: selDisplay,
      action_type: "OFF",
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

//post action for display OFF from toggle with get display action
export const postActionOFFAndGetDisplay =
  (selDisplay: any) => (dispatch: any, getState: any) => {
    dispatch(loadingDisplayStart());
    dispatch({ type: POST_ACTION_REQUEST });

    return (
      postActionOFF(selDisplay).then((response) => {
        console.log("Posted action successful, turning display off");
        dispatch({ response, type: POST_ACTION_SUCCESS });
        dispatch({ response, type: GET_DISPLAY_REQUEST });
        (getDisplayApi()).then(
          (response) => {
            console.log("get display success");
            dispatch(loadingDisplayFinish());
            dispatch({ response, type: GET_DISPLAY_SUCCESS });
            
          },
          (error: any) => {
            console.log("Error happened during get display");
            dispatch({ error, type: GET_DISPLAY_FAILURE });
            dispatch(loadingDisplayFinish());
          }
        );
      }),
      (error: any) => {
        console.log("Error happened during posting action");
        dispatch({ error, type: POST_ACTION_FAILURE });
        dispatch(loadingDisplayFinish());
      }
    );
  };

//----youtube playback action-----------------
export const postActionYoutube = (displayId: any, url: string) => {
  let apiName = "pelzer-cloud-api";
  let path = "/action";
  let myInit = {
    headers: {},
    body: {
      display_id: displayId, // action:{"url":"https://www.youtube.com/watch?v=fSp2C7QPH8M&t=1954s"}
      action_type: "YOUTUBE",
      action: {
        url: url,
      },
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const postActionAndLoadYoutube =
  (displayId: any, url: string) =>
  (dispatch: any, getState: any, getState1: any) => {
    dispatch(loadingYoutubeStart());
    dispatch({ type: POST_ACTION_REQUEST });

    return (
      postActionYoutube(displayId, url).then((response) => {
        console.log("Posted action successful, starting youtube playback");
        // dispatch(getDisplay());
        dispatch({ response, type: POST_ACTION_SUCCESS });
        dispatch(loadingYoutubeComplete());
      }),
      (error: any) => {
        console.log("Error happened during posting action");
        dispatch({ error, type: POST_ACTION_FAILURE });
        dispatch(loadingYoutubeComplete());
      }
    );
  };


  export const postActionStopYoutube = (displayId: any) => {
    let apiName = "pelzer-cloud-api";
    let path = "/action";
    let myInit = {
      headers: {},
      body: {
        display_id: displayId,
        action_type: "YOUTUBESTOP",
      },
      response: true,
    };
    return apiCall("post", apiName, path, myInit);
  };
  
  export const postActionAndStopYoutube =
    (displayId: any) =>
    (dispatch: any, getState: any, getState1: any) => {
      dispatch({ type: POST_ACTION_REQUEST });
  
      return (
        postActionStopYoutube(displayId).then((response) => {
          console.log("Posted action successful, Stoping youtube playback");
          dispatch({ response, type: POST_ACTION_SUCCESS });
        }),
        (error: any) => {
          console.log("Error happened during posting action");
          dispatch({ error, type: POST_ACTION_FAILURE });
        }
      );
    };

export const YOUTUBE_LOADING_START = "YOUTUBE_LOADING_START";
export const YOUTUBE_LOADING_COMPLETE = "YOUTUBE_LOADING_COMPLETE";

export function loadingYoutubeStart() {
  return { type: YOUTUBE_LOADING_START };
}
export function loadingYoutubeComplete() {
  return { type: YOUTUBE_LOADING_COMPLETE };
}
