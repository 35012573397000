import React,{useState} from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { addCircleOutline, chevronForwardOutline } from "ionicons/icons";
import { IonImg, IonRow, IonIcon, IonButton, IonSpinner } from "@ionic/react";
import "./ImageComponent.scss";
import { connect } from "react-redux";
import { setTimeout } from "timers";

interface buttonProps {
  clickHandler?: Function;
  style?: any;
  displayYoutubeIcon?: boolean;
  display?: any;
  youtubeImage?: string;
  currentStatus?: string;
  configDataIndex?: any;
}
const mapStateToProps = (state: any) => ({
  youtubeLoading: state.actions.loading,
  youtubePlaying: state.actions.youtubePlaying,
  playConfigDisplayIndex: state.display.playConfigYoutubeIcon,
  YoutubePlayConf: state.display.items,
});

type Props = WithTranslation & ReturnType<typeof mapStateToProps> & buttonProps;

const ImageComponent: React.FC<Props> = ({
  clickHandler,
  style,
  displayYoutubeIcon,
  display,
  youtubeImage,
  currentStatus,
  youtubeLoading,
  youtubePlaying,
  playConfigDisplayIndex,
  YoutubePlayConf,
  configDataIndex,
  ...props
}) => {

  function openMediaList() {
    clickHandler?.();
  }


  return (
    <div className="image-component-wrapper">
      <IonRow>
        {display !== null ? (
          <>
            <IonImg
              src={display.media_config.thumbnailUrl}
              style={
                currentStatus === "OFF"
                  ? { opacity: 0.2 }
                  :  displayYoutubeIcon === true 
                  ? { opacity: 0.5 }
                  : { opacity: 1 }
              }
              onClick={openMediaList}
              className="media-thumbnail-image"
            />
            {youtubeLoading === true ? (
              <IonSpinner
                name="crescent"
                className="youtube-spinner"
                color="dark"
              />
            ) : displayYoutubeIcon === true ? (
              <div className="show-youtube-icons">
                <IonImg src={youtubeImage} className="youtube-play-icons" />
              </div>
            ) : null}
          </>
        ) : (
          <IonButton className="ion-button" onClick={openMediaList}>
            <IonIcon icon={addCircleOutline} className="plus-icon" />
          </IonButton>
        )}
      </IonRow>
    </div>
  );
};

export default withTranslation()(connect(mapStateToProps)(ImageComponent));
