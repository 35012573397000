import { CALL_API, apiCall } from "../../common/api";

export const GET_MEDIA_REQUEST = "GET_MEDIA_REQUEST";
export const GET_MEDIA_FAILURE = "GET_MEDIA_FAILURE";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";

export const getMedia = () => ({
  [CALL_API]: {
    types: [GET_MEDIA_REQUEST, GET_MEDIA_SUCCESS, GET_MEDIA_FAILURE],
    apiName: "pelzer-cloud-api",
    path: "/media?display_id",
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

//with id
export const getMediaId = (displayId: string) => ({
  [CALL_API]: {
    types: [GET_MEDIA_REQUEST, GET_MEDIA_SUCCESS, GET_MEDIA_FAILURE],
    apiName: "pelzer-cloud-api",
    path: "/media?display_id=" + displayId,
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const GET_SEARCH_REQUEST = "GET_SEARCH_REQUEST";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_SEARCH_FAILURE = "GET_SEARCH_FAILURE";

export const getMediaSearch = () => ({
  [CALL_API]: {
    types: [GET_SEARCH_REQUEST, GET_SEARCH_SUCCESS, GET_SEARCH_FAILURE],
    apiName: "pelzer-cloud-api",
    path: "/media/search",
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

export const DOWNLOAD_MEDIA_REQUEST = "DOWNLOAD_MEDIA_REQUEST";
export const DOWNLOAD_MEDIA_FAILURE = "DOWNLOAD_MEDIA_FAILURE";
export const DOWNLOAD_MEDIA_SUCCESS = "DOWNLOAD_MEDIA_SUCCESS";

const downloadToDeviceApi = (mediaId: string, displayId: string) => {
  let apiName = "pelzer-cloud-api";
  let path = "/media/download_to_device";
  let myInit = {
    headers: {},
    body: {
      display_id: displayId,
      media_id: mediaId,
    },
    response: true,
  };
  return apiCall("post", apiName, path, myInit);
};

export const downloadToDevice =
  (mediaId: string, displayId: string) => (dispatch: any, getState: any) => {
    dispatch({ type: DOWNLOAD_MEDIA_REQUEST });

    return (
      downloadToDeviceApi(mediaId, displayId).then((response) => {
        console.log(
          "Download Media successful, fetching all media again",
          response
        );
        dispatch({ response, type: DOWNLOAD_MEDIA_SUCCESS, mediaId: mediaId });
      }),
      (error: any) => {
        console.log("Error happened during download Media");
        dispatch({ error, type: DOWNLOAD_MEDIA_FAILURE });
      }
    );
  };

export const YOUTUBE_SEARCH_REQUEST = "YOUTUBE_SEARCH_REQUEST";
export const YOUTUBE_SEARCH_FAILURE = "YOUTUBE_SEARCH_FAILURE";
export const YOUTUBE_SEARCH_SUCCESS = "YOUTUBE_SEARCH_SUCCESS";

export const getYoutubeSearchApi = (query: string) => (dispatch: any) => {
  console.log("Starting youtube search dispatch from mediaAction");
  let API_PATH = "https://www.googleapis.com/youtube/v3/search";
  dispatch({ type: YOUTUBE_SEARCH_REQUEST });

  return (
    fetch(
      `${API_PATH}?part=snippet&maxResults=50&q=${query}&type=video&key=${"AIzaSyDbhMo_VntLt55bW9XmgRr3mBwv1hKDD9I"}`
    )
      .then((response) => response.json())
      .then((data) => dispatch({ data, type: YOUTUBE_SEARCH_SUCCESS })),
    (error: any) => {
      console.log("Error happened during search request");
      dispatch({ error, type: YOUTUBE_SEARCH_FAILURE });
    }
  );
};
