import {
  GET_MEDIA_REQUEST,
  GET_MEDIA_FAILURE,
  GET_MEDIA_SUCCESS,
  GET_SEARCH_REQUEST,
  GET_SEARCH_FAILURE,
  GET_SEARCH_SUCCESS,
  DOWNLOAD_MEDIA_REQUEST,
  DOWNLOAD_MEDIA_FAILURE,
  DOWNLOAD_MEDIA_SUCCESS,
  YOUTUBE_SEARCH_REQUEST,
  YOUTUBE_SEARCH_FAILURE,
  YOUTUBE_SEARCH_SUCCESS,
} from "./MediaAction";

export interface MediaType {
  id: number;
  name: string;
  contentType: string;
  aspectRatio: string;
  width: string;
  height: string;
}

export interface localStorageMedia {
  display_id: string;
  state: string;
}

export interface MediaItem {
  id: string;
  name: string;
  thumbnailUrl: string;
  url: string;
  mediaType?: MediaType;
  downloadedOnDisplay?: localStorageMedia[];
}

export interface YouTubeSearchItem {
  id: string;
  link: string;
  kind: string;
  publishedAt: string;
  channelTitle: string;
  channelId: string;
  title: string;
  description: string;
  thumbnails: searchThumbnails;
}

export interface searchThumbnails {
  default?: YouTubeThumbnail;
  medium?: YouTubeThumbnail;
  high?: YouTubeThumbnail;
  standard?: YouTubeThumbnail;
  maxres?: YouTubeThumbnail;
}

export interface YouTubeThumbnail {
  url: string;
  width: number;
  height: number;
}

interface State {
  loading: boolean;
  downloading: boolean;
  purchasedItems: MediaItem[];
  storeItems: MediaItem[];
  youtubeItems: YouTubeSearchItem[];
}

const initialStateMedia: State = {
  loading: false,
  downloading: false,
  purchasedItems: [],
  storeItems: [],
  youtubeItems: [],
};

export const media = (state: State = initialStateMedia, action: any) => {
  switch (action.type) {
    case GET_MEDIA_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_MEDIA_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_MEDIA_SUCCESS:
      console.log("In response for GET_MEDIA_SUCCESS");
      return Object.assign({}, state, {
        loading: false,
        purchasedItems: action.response.data,
      });
    case GET_SEARCH_REQUEST:
      return Object.assign({}, state, { loading: true });
    case GET_SEARCH_FAILURE:
      return Object.assign({}, state, { loading: false });
    case GET_SEARCH_SUCCESS:
      console.log("In response for GET_SEARCH_SUCCESS");
      return Object.assign({}, state, {
        loading: false,
        storeItems: action.response.data,
      });
    case DOWNLOAD_MEDIA_REQUEST:
      return Object.assign({}, state, { downloading: true });
    case DOWNLOAD_MEDIA_FAILURE:
      return Object.assign({}, state, { downloading: false });
    case DOWNLOAD_MEDIA_SUCCESS:
      return Object.assign({}, state, { downloading: false });

    case YOUTUBE_SEARCH_REQUEST:
      return Object.assign({}, state, { loading: true });
    case YOUTUBE_SEARCH_FAILURE:
      return Object.assign({}, state, { loading: false });
    case YOUTUBE_SEARCH_SUCCESS:
      console.log("In response for YOUTUBE_SEARCH_SUCCESS");
      console.log(action);
      const mediaItems = action.data.items.map((i: any) =>
        transformYoutubeMedia(i)
      );
      return Object.assign({}, state, {
        loading: false,
        youtubeItems: mediaItems,
      });
    default:
      return state;
  }
};

function transformYoutubeMedia(youtubeMediaItem: any) {

  const mediaItem: MediaItem = {
    id: youtubeMediaItem.id.videoId,
    name: youtubeMediaItem.snippet.title,
    thumbnailUrl: youtubeMediaItem.snippet.thumbnails.high.url,
    url: "https://www.youtube.com/watch?v="+youtubeMediaItem.id.videoId
  }
  return mediaItem;
}
