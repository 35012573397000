import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import Typography from "../typo/Typography";
import { MediaItem } from "../../data/media/Media";
import { withTranslation, WithTranslation } from "react-i18next";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import "./MediaListItem.scss";
import Spinner from "../spinner/Spinner";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ImportantDevicesRounded from "@material-ui/icons/ImportantDevicesRounded";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import MailTo from "../../common/MailTo";
import { postActionAndLoadYoutube } from "../../data/action/applyAction";

interface MediaCustomProps {
  onSelection(media: MediaItem): void;
  onSelectDownload(media: MediaItem): void;
  mediaItem: MediaItem;
  display: any;
  mode: string;
}

const mapDispatchToProps = (dispatch: any) => ({
  postActionAndReloadDisplayyoutubeDispatch: (displayId: any, url: string) =>
    dispatch(postActionAndLoadYoutube(displayId, url)),
});

const mapStateToProps = (state: any) => ({
  stateDownloading: state.media.downloading,
});
type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  MediaCustomProps;

const MediaListItem: React.FC<Props> = (props) => {
  const { t, display, mediaItem, onSelection, onSelectDownload, mode } = props;

  const [spinner, setSpinner] = useState(false);
  const [storeOptions, setStoreOptions] = useState(false);
  const [youtubeOptions, setYoutubeOptions] = useState(false);

  const calculateDownloadedState = () => {
    if (mode === "home") {
      if (
        mediaItem.downloadedOnDisplay &&
        mediaItem.downloadedOnDisplay.length > 0
      ) {
        let len = mediaItem.downloadedOnDisplay.length;
        for (let j = 0; j < len; j++) {
          if (
            String(mediaItem.downloadedOnDisplay[j].display_id) ===
              String(display) &&
            mediaItem.downloadedOnDisplay[j].state === "downloaded"
          ) {
            return "downloaded";
          } else if (
            String(mediaItem.downloadedOnDisplay[j].display_id) ===
              String(display) &&
            mediaItem.downloadedOnDisplay[j].state === "downloading"
          ) {
            return "downloading";
          }
        }
      }
    }
    return "notDownloaded";
  };

  const [mediaDownloaded, setMediaDownloaded] = useState<String>("undefined");

  useEffect(() => {
    setMediaDownloaded(calculateDownloadedState());
  }, [mediaItem]);

  function onClickOnMedia() {
    setSpinner(true);
    setTimeout(() => {
      setSpinner(false);
    }, 20000);
  }

  const renderMediaItem = (opacity: number = 1.0) => {
    return <IonImg src={mediaItem.thumbnailUrl} style={{ opacity: opacity }} />;
  };

  const renderMediaItemToDownload = () => {
    return (
      <div>
        {renderMediaItem(0.2)}
        <span className="download-center">
          <GetAppRoundedIcon fontSize="small" className="download-icon" />
          <Typography
            variant="body-small"
            color="subdued"
            className="download-text"
          >
            {t("mediaitem-download-text")}
          </Typography>
        </span>
      </div>
    );
  };

  const renderMediaItemWaiting = () => {
    return (
      <div>
        {renderMediaItem(0.2)}
        <Spinner className="download-spinner" />
      </div>
    );
  };

  const renderStoreItem = () => {
    return (
      <div>
        {renderMediaItem()}
        <div className="preview-and-purchase-app">
          <IonGrid>
            <IonRow
              className="icons-row-preview"
              onClick={() => {
                onSelection(mediaItem);
                setStoreOptions(true);
              }}
            >
              <IonCol>
                <ImportantDevicesRounded
                  fontSize="medium"
                  className="preview-svg-img1 moreCardIonThumbnailIcon"
                />
                <Typography
                  variant="body"
                  color="dark"
                  className="purchase-typo-text"
                >
                  {t("mediaitem-preview")}
                </Typography>
              </IonCol>
              <IonCol>
                <AddShoppingCartIcon
                  fontSize="medium"
                  className="preview-svg-img1 moreCardIonThumbnailIcon"
                />
                <Typography
                  variant="body"
                  color="dark"
                  className="purchase-typo-text"
                >
                  {t("mediaitem-purchase")}
                </Typography>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    );
  };

  const renderYoutubeItem = () => {
    return (
      <div>
        {renderMediaItem()}
        <div className="youtube-list">
          <IonGrid>
            <IonRow
              onClick={() => {
                onSelection(mediaItem);
              }}
            >
              <IonCol>
                <Typography variant="body" className="youtube-title-text">
                  <span>{mediaItem.name}</span>
                </Typography>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    );
  };

  return (
    <div
      className="image-bild-button"
      onClick={() => {
        if (mode === "home") {
          if (
            mediaDownloaded !== "downloaded" &&
            mediaDownloaded !== "downloading"
          ) {
            onClickOnMedia();
            onSelectDownload(mediaItem);
          } else if (mediaDownloaded === "downloaded") {
            onSelection(mediaItem);
          }
        } else if (mode === "store") {
          setStoreOptions(true);
          if (storeOptions === true) {
            setStoreOptions(false);
          }
        } else if (mode === "youtubeSearch") {
          setYoutubeOptions(true);
          if (youtubeOptions === true) {
            setYoutubeOptions(false);
          }
        }
      }}
    >
      {mediaDownloaded === "undefined" ? (
        renderMediaItemWaiting()
      ) : mode == "home" && mediaDownloaded == "downloaded" ? (
        renderMediaItem()
      ) : mode == "home" && (spinner || mediaDownloaded == "downloading") ? (
        renderMediaItemWaiting()
      ) : mode == "home" && mediaDownloaded == "notDownloaded" ? (
        renderMediaItemToDownload()
      ) : mode === "store" && storeOptions === true ? (
        renderStoreItem()
      ) : mode === "youtubeSearch" ? (
        renderYoutubeItem()
      ) : null}
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MediaListItem)
);
