import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonText,
  IonIcon,
  IonButton,
  IonItem,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import "./Header.scss";
import "../../theme/variables.scss";
import logo from "../../assets/images/pelzerlogo.png";
import Typography from "../typo/Typography";

interface headerProps {
  showMenu?: boolean;
  handler?: Function;
  showBackButton?: boolean;
  value?: any;
  showLogo?: boolean;
  headerLable?: string;
  router_link?: string;
}

const HeaderComponent: React.FC<headerProps> = ({
  showMenu,
  value,
  handler,
  showBackButton = false,
  showLogo,
}) => {
  function backButtonHandler() {
    handler?.();
  }
  return (
    <>
      <IonHeader translucent className="ion-no-border" mode="ios">
        {showBackButton && !showLogo ? (
          <IonToolbar className="toolbar" mode="ios">
            <div className="header-container">
              <IonButton onClick={backButtonHandler} className="back-button">
                <IonIcon
                  className="back-arrow"
                  slot="icon-only"
                  ios={chevronBackOutline}
                  md={chevronBackOutline}
                />
              </IonButton>
              <IonText className="heading">
                <Typography variant="body" color="dark">
                  {value}
                </Typography>
              </IonText>
              <div className="empty-container"></div>
            </div>
          </IonToolbar>
        ) : (
          <IonToolbar className="toolbar">
            <img className="pelzerlogo" src={logo} alt="Logo" />
          </IonToolbar>
        )}
      </IonHeader>
    </>
  );
};

export default HeaderComponent;
