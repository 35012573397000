import { CALL_API, apiCall } from "../../common/api";

export const GET_DISPLAY_REQUEST = "GET_DISPLAY_REQUEST";
export const GET_DISPLAY_FAILURE = "GET_DISPLAY_FAILURE";
export const GET_DISPLAY_SUCCESS = "GET_DISPLAY_SUCCESS";
export const DISPLAY_CHANGE = "DISPLAY_CHANGE";
export const PLAY_CONFIG = "PLAY_CONFIG";

export const getDisplay = () => ({
  [CALL_API]: {
    types: [GET_DISPLAY_REQUEST, GET_DISPLAY_SUCCESS, GET_DISPLAY_FAILURE],
    apiName: "pelzer-cloud-api",
    path: "/display",
    method: "get",
    myInit: {
      headers: {},
      response: true,
    },
  },
});

// For post action(ON/OFF) to reload/getdisplay after turning ON/OFF
export const getDisplayApi = () => {
  let apiName = "pelzer-cloud-api";
  let path = "/display";
  let myInit = {
    headers: {},
    body: {},
    response: true,
  };
  return apiCall("get", apiName, path, myInit);
};

export const changeActiveDisplay = (selDisplay: string) => (dispatch: any, getState: any) => { 
  dispatch({ type: DISPLAY_CHANGE, selDisplay });
};

export const playConfigType = (PlayConfigMedia:any) => (dispatch: any, getState: any) => {
  dispatch({ type: PLAY_CONFIG, PlayConfigMedia });
};
  
