import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonToast,
} from "@ionic/react";
import DisplayYoutube from "../displayYoutube/DisplayYoutube";
import "./DisplayCard.scss";
import "../../theme/variables.scss";
import MediaList from "../mediaList/MediaList";
import Typography from "../../components/typo/Typography";
import { DisplayItem } from "../../data/display/Display";
import { withTranslation, WithTranslation } from "react-i18next";
import { downloadToDevice, getMediaId } from "../../data/media/MediaAction";
import {
  postActionAndReloadDisplay,
  postActionONAndGetDisplay,
  postActionOFFAndGetDisplay,
  postActionAndLoadYoutube,
  postActionAndStopYoutube,
  playConfigYoutubeMedia,
} from "../../data/action/applyAction";
import { MediaItem } from "../../data/media/Media";
import Spinner from "../spinner/Spinner";
import ImageComponent from "../../components/imagecomponent/ImageComponent";
import youtubeImage from "../../assets/images/youtubeImage.png";
import youtubeStartIcon from "../../assets/images/youtube.svg";
import youtubeStopIcon from "../../assets/images/youtubeStopIcon.svg";
import youtubeActive from "../../assets/images/youtubeActive.svg";
import onoff from "../../assets/images/onoff.svg";
import onoffActive from "../../assets/images/onoffActive.svg";
import background from "../../assets/images/background.svg";
import backgroundActive from "../../assets/images/backgroundActive.svg";
import { YoutubeItem } from "../../data/action/action";
import RippleComponent from "../../components/ripple/RippleComponent";

interface customProps {
  display: DisplayItem;
  fetchDisplay: (value: boolean) => void
}

const mapStateToProps = (state: any) => ({
  youtube: state.media.youtubeItems,
  actionsLoading: state.actions.displayloading,
  displayLoading: state.display.loading,
  playConfigDisplayIndex: state.display.playConfigYoutubeIcon,
   numberOfDisplays : state.display.items.length,
});

const mapDispatchToProps = (dispatch: any) => ({
  postActionAndReloadDisplayDispatch: (id: string, selDisplay: any) =>
    dispatch(postActionAndReloadDisplay(id, selDisplay)),

  postActionONAndGetDisplayDispatch: (selDisplay: any) =>
    dispatch(postActionONAndGetDisplay(selDisplay)),

  postActionOFFAndGetDisplayDispatch: (selDisplay: any) =>
    dispatch(postActionOFFAndGetDisplay(selDisplay)),

  postActionAndReloadDisplayyoutubeDispatch: (displayId: any, url: any) =>
    dispatch(postActionAndLoadYoutube(displayId, url)),

  downloadToDeviceDispatch: (mediaId: string, displayId: string) => {
    dispatch(downloadToDevice(mediaId, displayId));
  },

  postActionAndStopDisplayyoutubeDispatch: (displayId: any) =>
    dispatch(postActionAndStopYoutube(displayId)),

  playConfigYoutubeMediaDispatch: (PlayConfigMedia: any) =>
  dispatch(playConfigYoutubeMedia(PlayConfigMedia)),
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  customProps;

const DisplayCard: React.FC<Props> = ({
  display,
  postActionAndReloadDisplayDispatch,
  postActionONAndGetDisplayDispatch,
  postActionOFFAndGetDisplayDispatch,
  actionsLoading,
  displayLoading,
  postActionAndReloadDisplayyoutubeDispatch,
  postActionAndStopDisplayyoutubeDispatch,
  t,
  downloadToDeviceDispatch,
  playConfigDisplayIndex,
  numberOfDisplays,
  fetchDisplay
}) => {
  const [showMediaList, setShowMediaList] = useState(false);
  const [toastOFF, setToastOFF] = useState(false);
  const [toastON, setToastON] = useState(false);
  const [toastURL, setToastURL] = useState(false);
  const [showYoutubePage, setShowYoutubePage] = useState(false);
  const [youtubePlaying, setYoutubePlaying] = useState(false);
  const [displayStopButton, setDisplayStopButton] = useState(true);
  const [displayStatus, setDisplayStatus] = useState("");
  const [urlShareOnce, seturlShareOnce] = useState(false);
  const [UrlValue, setUrlValue] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [dataShareOnce, setdataShareOnce] = useState(true);


  const openMediaList = () => {
    setShowMediaList(true);
  };

  const onCloseMediaList = () => {
    setShowMediaList(false);
  };

  const openYoutubePage = () => {
    setShowYoutubePage(true);
  };

  const stopYoutubeVideo = () => {
    console.log("Stoping playback of youtube video");
    postActionAndStopDisplayyoutubeDispatch(display.id)
  };

  const onCloseYoutubePage = () => {
    setShowYoutubePage(false);
      fetchDisplay(true)
  };

  const onSelectMedia = (media: MediaItem) => {
    console.log("After select image post action and get display");
    postActionAndReloadDisplayDispatch(media.id, display.id);
    setShowMediaList(false);
  };

  const onSelectYoutube = (youtube: any) => {
    console.log("Youtube display.id from Search option", display.id);
    console.log("Youtube url from Search option", youtube.url);
    postActionAndReloadDisplayyoutubeDispatch(display.id, youtube.url);
    setShowYoutubePage(false);
    setShowMediaList(false);
    setSpinner(false);
  };


  const onSelectSetON =(display: DisplayItem) => {
    postActionONAndGetDisplayDispatch(display.id);
  };

  const onSelectSetOFF = (display: DisplayItem) => {
    postActionOFFAndGetDisplayDispatch(display.id);
  };

  const onSelectDownload = (selectedMedia: MediaItem) => {
    console.log(
      "Start downloading %s to display %s",
      selectedMedia.id,
      display.id
    );
    downloadToDeviceDispatch(selectedMedia.id, display.id);
  };

// Share links via App dispatched from here
  function urlSharedFromApp() {
    const parsedUrl = (new URL(window.document.location.href)).searchParams.get('text');
    let youtubeUrl = parsedUrl;
    if (!urlShareOnce && youtubeUrl !== null) {
      console.log("URL shared from app share list", youtubeUrl);
      seturlShareOnce(true);
      setUrlValue(youtubeUrl);
      postActionAndReloadDisplayyoutubeDispatch(display.id, youtubeUrl);
      setShowYoutubePage(false);
      setShowMediaList(false);
    }
  }
  urlSharedFromApp();

    useEffect(() =>{
        let playConfig = display.playConfig
        let playYoutube = false
        for(let i=0; i<playConfig.length; i++){
            if(playConfig[i].outputs !== undefined && playConfig[i].outputs.length > 0)
                playYoutube = true
        }
        setYoutubePlaying(playYoutube);

    },[display])

let textPosition:any 
if (numberOfDisplays===1){
  textPosition = true
}
if (numberOfDisplays>1){
  textPosition = false
}

  return (
    <IonGrid className="cardComponent-grid">
      <IonRow>
        <IonCard className={"cardComponent-wrapper"} button>
          {actionsLoading ? (
            <Spinner className="switching-spinner" />
          ) : (
            <ImageComponent
              displayYoutubeIcon={youtubePlaying}
              display={display.status}
              clickHandler={openMediaList}
              youtubeImage={youtubeImage}
              currentStatus={display.status.state}
            />
          )}
          <IonCardContent>
            <IonRow class="first-row">
              <Typography
                variant="body"
                color="subdued"
                className="first-row-title "
              >
                {display.displayName}
              </Typography>
            </IonRow>
            <hr />
            <IonRow class="second-icon-row">
              <IonCol
                size="4"
              // text-align="centre"
              >
                <RippleComponent
                  src={!youtubePlaying ? youtubeStartIcon : youtubeStopIcon}
                  typoText="Youtube"
                  clsName={textPosition ? "youtube-toggle-text" : "youtube-text"}
                  clickHandler={() =>
                    {
                      if (youtubePlaying) {
                        stopYoutubeVideo();
                      } else {
                        setShowYoutubePage(true);
                      }
                    }
                  }
                />
              </IonCol>

              <IonCol
                size="4"
              >
                <RippleComponent
                  src={background}
                  typoText="Background"
                  clickHandler={openMediaList}
                  clsName={textPosition?"background-toggle-text":"background-text"}
                />
              </IonCol>

              <IonCol
                size="4"
              >
                <RippleComponent
                  src={onoff}
                  typoText="On/Off"
                  clsName={textPosition?"switch-toggle-text":"switch-text"}
                  clickHandler={() => {
                    //ON/OFF post action with condition from state to trigger display ON/OFF
                    if (display.status.state == "ON") {
                      onSelectSetOFF(display);
                      setToastOFF(true);
                      console.log("Display status is ON, Setting display OFF");

                      display.status.state = "OFF";
                      setDisplayStatus(display.status.state);
                      // console.log("status****",display.status.state);
                    } else if (display.status.state == "OFF") {
                      onSelectSetON(display);
                      setToastON(true);
                      console.log(
                        "Display status is OFF, Setting default media"
                      );

                      display.status.state = "ON";
                      setDisplayStatus(display.status.state);
                      // console.log("new status****",display.status.state);
                    }
                  }}
                />
              </IonCol>

              <IonToast
                mode="ios"
                color="secondary"
                isOpen={toastOFF}
                onDidDismiss={() => setToastOFF(false)}
                message={t("alert-toast-off")}
                duration={500}
              />
              <IonToast
                mode="ios"
                color="secondary"
                isOpen={toastON}
                onDidDismiss={() => setToastON(false)}
                message={t("alert-toast-on")}
                duration={500}
              />
              <IonToast
                mode="ios"
                color="secondary"
                isOpen={toastURL}
                onDidDismiss={() => setToastURL(false)}
                message={"Please enter correct URL"}
                duration={1000}
              />
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonRow>
      <IonModal
        cssClass="mediaModal"
        isOpen={showMediaList}
        onDidDismiss={onCloseMediaList}
      >
        <MediaList
          mode={"home"}
          onClose={onCloseMediaList}
          onSelection={onSelectMedia}
          selectedDisplay={display.id}
          onSelectDownload={onSelectDownload}
        />
      </IonModal>
      <IonModal
        cssClass="mediaModal"
        isOpen={showYoutubePage}
        onDidDismiss={onCloseYoutubePage}
        onDidPresent={() => {
            fetchDisplay(false)
         }}
      >
        <DisplayYoutube
          onClose={onCloseYoutubePage}
          onSelection={onSelectYoutube}
        />
      </IonModal>
    </IonGrid>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DisplayCard)
);
