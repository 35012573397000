import { useEffect } from "react";
import { connect } from "react-redux";
import { IonContent, IonPage, IonCardHeader } from "@ionic/react";
import HeaderComponent from "../../components/header/Header";
import Typography from "../../components/typo/Typography";
import { MediaItem } from "../../data/media/Media";
import { withTranslation, WithTranslation } from "react-i18next";
import "./MediaList.scss";
import { getMediaId } from "../../data/media/MediaAction";
import Spinner from "../spinner/Spinner";
import MediaListItem from "./MediaListItem";

interface MediaCustomProps {
  onSelection(media: MediaItem): void;
  onClose(): void;
  selectedDisplay?: any;
  onSelectDownload(media: MediaItem): void;
  mode: string;
}

const mapStateToProps = (state: any) => ({
  media: state.media.purchasedItems,
  mediaLoading: state.media.loading,
  store: state.media.storeItems,
  youtube: state.media.youtubeItems,
  getDisplayDownloaded: state.display.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMediaIdDispatch: (selectedDisplay: any) => {
    dispatch(getMediaId(selectedDisplay));
  },
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  MediaCustomProps;

const MediaList: React.FC<Props> = (props) => {
  const {
    media,
    mediaLoading,
    store,
    youtube,
    onSelection,
    onClose,
    t,
    selectedDisplay,
    getMediaIdDispatch,
    onSelectDownload,
    mode,
  } = props;

  useEffect(() => {
    // Load media directly on mount and repeat in interval afterwards
    if (mode === "home") {
      getMediaIdDispatch(selectedDisplay);
    }
    const interval = setInterval(() => {
      if (document.hasFocus() === true) {
        console.log("Media_list is used for page %s ", mode);
        if (mode === "home") {
          getMediaIdDispatch(selectedDisplay);
        }
      } else {
        console.log("Media_list Page is Inactive");
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <IonPage>
        <HeaderComponent
          headerLable="common.header"
          showBackButton={true}
          handler={onClose}
          value={
            mode === "home"
              ? t("medialist-medialist")
              : mode === "store"
              ? t("medialist-mediastore")
              : mode === "youtubeSearch"
              ? t("medialist-youtube-search")
              : null
          }
        />
        <IonContent className="media-container">
          <IonCardHeader>
            <Typography
              variant="body"
              color="subdued"
              className="media-heading"
            >
              {mode === "home"
                ? t("medialist-medialist-heading-text")
                : mode === "store"
                ? t("medialist-mediastore-heading-text")
                : mode === "youtubeSearch"
                ? t("medialist-youtube-search-heading-text")
                : null}
            </Typography>
          </IonCardHeader>
          <div className="img-wrapper">
              <span>
                {mode === "home"
                  ? (mediaLoading && media.length === 0) ? (
                    <Spinner className="media-list-spinner" />
                  ) : ( 
                    media.map((m: MediaItem, index: any) => (
                      <MediaListItem
                        mode={mode}
                        key={index}
                        mediaItem={m}
                        display={selectedDisplay}
                        onSelection={onSelection}
                        onSelectDownload={onSelectDownload}
                      />
                    )))
                  : mode === "store"
                  ? (mediaLoading && store.length === 0) ? (
                    <Spinner className="media-list-spinner" />
                  ) : ( 
                    store.map((m: MediaItem, index: any) => (
                      <MediaListItem
                        mode={mode}
                        key={index}
                        mediaItem={m}
                        display={selectedDisplay}
                        onSelection={onSelection}
                        onSelectDownload={onSelectDownload}
                      />
                    )))
                  : mode === "youtubeSearch"
                  ? (mediaLoading && youtube.length === 0) ? (
                    <Spinner className="media-list-spinner" />
                  ) : ( 
                    youtube.map((m: MediaItem, index: any) => (
                      <MediaListItem
                        mode={mode}
                        key={index}
                        mediaItem={m}
                        display={selectedDisplay}
                        onSelection={onSelection}
                        onSelectDownload={onSelectDownload}
                      />
                    )))
                  : null}
              </span>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MediaList)
);
