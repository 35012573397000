import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonInput,
  IonCol,
  IonSearchbar,
  IonModal,
  IonButton
} from "@ionic/react";
import HeaderComponent from "../../components/header/Header";
import Typography from "../../components/typo/Typography";
import { withTranslation, WithTranslation } from "react-i18next";
import "./DisplayYoutube.scss";
import MediaList from "../mediaList/MediaList";
import { getYoutubeSearchApi } from "../../data/media/MediaAction";

interface youtubeCustomProps {
  onSelection(actions: any): void;
  onClose(): void;
}

const mapStateToProps = (state: any) => ({
  actions: state.actions.purchasedItems,
  youtubeItems: state.media.youtubeItems,
});

const mapDispatchToProps = (dispatch: any) => ({
  getYoutubeSearchApiDispatch: (query: string) =>
    dispatch(getYoutubeSearchApi(query)),
});

type Props = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  youtubeCustomProps;

const DisplayYoutube: React.FC<Props> = (props) => {
  const {
    actions,
    onSelection,
    onClose,
    t,
    getYoutubeSearchApiDispatch,
    youtubeItems,
  } = props;
  const [youtubeText, setyoutubeText] = useState("");
  const [showSearchList, setShowSearchList] = useState(false);

  const onClickSearch = () => {
    console.log("Starting youtube search dispatch");
    setShowSearchList(true);
    getYoutubeSearchApiDispatch(youtubeText);
  };

  const onCloseStoreList = () => {
    console.log("Closing store list");
    setShowSearchList(false);
  };
  
  const onSelectPurchase = () => {};

  return (
    <>
      <IonPage>
        <HeaderComponent
          headerLable="common.header"
          showBackButton={true}
          handler={onClose}
          value="Youtube Player"
        />

        <IonContent className="youtube-container" fullscreen>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <Typography
                  variant="heading"
                  color="dark"
                  className="youtube-heading"
                >
                  Youtube Player
                </Typography>
              </IonCol>
            </IonRow>
            <IonRow class="youtube-link-input-row">
              {/* <IonInput /> */}
              <IonSearchbar
                mode="ios"
                value={youtubeText}
                onIonChange={(e) => setyoutubeText(e.detail.value!)}
                placeholder={t("youtube-search-title")}
                class="youtube-link-input"
                type="text"
              ></IonSearchbar>
            </IonRow>

            <IonRow>
              <IonCol size="12" class="ion-text-center">
                <IonButton className="searchbutton" onClick={onClickSearch}>
                  Search
                </IonButton>
              </IonCol>
              <IonModal cssClass="mediaModal" isOpen={showSearchList}>
                <MediaList
                  mode={"youtubeSearch"}
                  onClose={onCloseStoreList}
                  onSelection={onSelection}
                  onSelectDownload={onSelectPurchase}
                />
              </IonModal>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DisplayYoutube)
);
